import React, { useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import Button, { ButtonStyle } from '@components/buttons/Button/Button';
import { WarningBox } from '@components/cards/messageBox';
import Warning from '@components/modal/info/Warning';
import { NavBar } from '@components/navigation/NavBar/NavBar';
import { PanelItem } from '@components/panels/PanelItems';
import CircularSpinner from "@components/spinners/Circular/Circular";
import useServiceHook from '@hooks/useServiceHook';
import PaymentMethodService, { ICardPaymentMethod } from '@services/paymentMethod/PaymentMethodService';
import { Country } from '@utils/constants/localisation';
import link from '@assets/images/link.svg';
import { getChargebeePaymentMethodsLink } from '../../utils/misc/functions';
import { useUserJourneyContext } from '@context/UserJourneyContext';

const LoadingSpinner = () => (
    <div data-testid="loading-spinner" style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
        {<CircularSpinner />}
    </div>
)
const PaymentMethodPanel = ({ payment_method }: { payment_method: ICardPaymentMethod }) => {
    const expiry_date = new Date(payment_method.expiry_year, payment_method.expiry_month - 1, 1);
    return (
        <>
            <PanelItem header='Payment Method' headerTextStyleCfg={{ bold: true }} value={`${payment_method.brand} ending ${payment_method.last4}`} />
            <PanelItem header='Expiry Date' headerTextStyleCfg={{ bold: true }} value={`${expiry_date.toLocaleString('en', { month: 'short' })}-${payment_method.expiry_year}`} />
        </>
    )
}
export default function ReviewCustomerPaymentMethod() {
    const navigate = useNavigate();
    const { custId, country } = useParams() as { custId: string, country: Country };
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const next_page: any = queryParams.get('next_page') == null ? '/' : queryParams.get('next_page');
    const next_activity: any = queryParams.get('next_activity')
    const noPaymentMethodMsg = 'No valid payment method present';
    let initial_message: string = 'Before proceeding, please review the customer\'s primary payment method:';
    let missing_payment_method_message: string = 'Please only continue if you have approval for the customer to make an offline payment.'
    if (next_activity != null) {
        initial_message = 'Before proceeding with the ' + next_activity + ', please review the customer\'s primary payment method:';
        missing_payment_method_message = 'Please only continue with the ' + next_activity + ' if you have approval for the customer to make an offline payment.'
    }

    const { formState } = useUserJourneyContext();
    const { personalInfo } = formState;
    
    const [loading, error, errMsg, result] = useServiceHook(
        PaymentMethodService.getCustomerCardPaymentMethod,
        [custId, country, personalInfo.firstName, personalInfo.lastName, personalInfo.email],
        true,
        [],
        undefined,
        [true] // only include primary payment method
    );

    const handleButtonClick = () => {

        const chargebeeLink = getChargebeePaymentMethodsLink(custId, country)

        // _blank opens up a new tab the user is then moved there with the focus method
        const newTab = window.open(chargebeeLink, "_blank")
        
        if (newTab) { newTab.focus() }
        
        navigate(`/customer/${country}/${custId}/overview`) } 

    return (
        <>
        <NavBar pageTitle="Review payment method" />
        {loading && <LoadingSpinner />}

        {!loading && result && !error && (
            <>
                {result['card_payment_methods'].length !== 0 && <p>{initial_message}</p>}

                {result['card_payment_methods'].map((payment_method: ICardPaymentMethod, index: number) => (
                    <div style={{ paddingTop: '1.5vh' }} key={`paymentMethod-div-${index}`}>
                        <PaymentMethodPanel payment_method={payment_method} />
                    </div>
                ))}

                {result['card_payment_methods'].length === 0 && (
                    <>
                        <div style={{ marginTop: '2vh' }}>
                            <WarningBox message={noPaymentMethodMsg} />
                        </div>
                        <div style={{ marginTop: '2vh' }}>{missing_payment_method_message}</div>
                        <div style={{ marginTop: '2vh' }}>
                            Alternately, please add or manage the customer payment methods:
                        </div>
                    </>
                )}

                <div style={{ margin: '2.5vh 0' }}>
                    <Button
                        label={result['card_payment_methods'].length === 0 ? "Continue" : "Next"}
                        onClickFunc={() => navigate(next_page)}
                    />
                </div>

                <div style={{ margin: '2.5vh 0' }}>
                    <Button
                        label="Manage payment methods in Chargebee"
                        iconSrc = {link}
                        btnType={ButtonStyle.tertiary}
                        onClickFunc={() => handleButtonClick()}
                    />
                </div>
            </>
        )}
    </>
    )
}