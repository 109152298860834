// Intended to be mainly used with the get subscription service
// results as the statuses are according to the mapping below.
export enum SubscriptionsStatus {
    active = 'Active',
    inactive = 'Inactive'
};

export enum SubscriptionPlan {
    flexiOwn = 'Flexi Own',
    flexi = 'Flexi',
    flexiOwnUsed = 'Splend Rent to Own - Used Vehicle',
    ravCreditHire = 'RAV Credit Hire',
    ravShareCover = 'RAV Share Cover',
    ravShortTerm = 'RAV Short Term Rental',
    preLoved = 'Pre-Loved Flexi-Own'
};

export const flexiOwnPlanNames = [  // values stored in ZohoCRM and which are reflecting FlexiOwn plan
    'splend rent-to-own', 'splend rent-to-buy',  // in UK
    'splend rent to own', 'splend rent-to-own - used car', 'splend rent-to-own - legacy', 'flexi-own', 'splend flexi-own',
    'flexi own', 'splend flexi-own', 'Splend Flexi-Own', 'flexi-own'  // in AU
];

export const flexiOwnUsedPlanNames = [  // values stored in ZohoCRM and which are reflecting Splend Rent to Own - Used Vehicle plan
    'splend rent to own - used vehicle', 'Splend Rent to Own - Used Vehicle' // in AU
];

export const ravCreditHirePlanNames = [  // values stored in ZohoCRM and which are reflecting RAV Credit Hire plan
    'RAV Credit Hire', 'rav credit hire' // in AU
];

export const ravShareCoverPlanNames = [  // values stored in ZohoCRM and which are reflecting RAV Sharecover plan
    'RAV Sharecover', 'rav sharecover', 'RAV Share Cover', 'rav share cover' // in AU
];

export const ravShortTermPlanNames = [  // values stored in ZohoCRM and which are reflecting RAV Short Term Rental plan
    'RAV Short Term Rental', 'rav short term rental', 'RAV Share Cover', 'rav share cover', 'rav short term rental platinum', 'RAV Short Term Rental Platinum' // in AU
];

export const preLovedPlanNames = [
    'Pre-Loved Flexi-Own', 'pre-loved flexi-own'
]
