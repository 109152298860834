import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';

import { useIsAuthenticated, useMsal } from "@azure/msal-react";

import { useAPIError } from "@context/ServerErrorContext";
import Warning from '@components/modal/info/Warning';


// should this be standardized somehow? so each service would follow some guidance in terms of what it returns
type Results = any;
// ------------- loading flag -- error flag --- errMsg -- service result
type IResult = [boolean | null, boolean | null, string, Results];


/**
 * Custom hook designed to be used alongside with a service. The hook calls the service
 * and keeps track of the request, in the way of returning whether it's in one of the
 * following states: loading, successfully & finished, failed & finished.
 *
 * Behavior:
 *  - service is being called
 *  - loading and error states are set to true, and false respectively, indicating an ongoing
 *    and not finished request
 *  - once it finishes, the loading state is set to false
 *  - depending on the case:
 *     -- loads/stores data provided by the service
 *     -- error flag/state can be set to true, indicating an unexpected and not covered
 *        scenario by the service - which SHALL NOT be the rule, but rather the exception
 *  - returns all collected information
 *
 * Other mentions:
 *  - useEffect hook used here, with its dependency set to the parameters list, because whenever
 *    one gets changed, it's considered that a new service call is required
 *  - Take care as the useEffect will not just fail but upon failure will return an empty array.
 *    This has caused a red herring on multiple occasions to if debugging the useServiceHook remember
 *    this behaviour.
 *
 * @param serviceToCall: can be any callable function
 * @param params: parameter list to pass to the function
 * @returns: object comprising info about the service call state and results when it got finished
 */

const defaultErrorMessage = `We’re sorry, we were unable to process your request. We will investigate the issue and in the meantime, please onboard the customer via an alternative method.`
const noChargebeeCustomerFoundMessage = "Chargebee customer not found, please create a chargebee customer using the zoho contact id as the chargebee customer ID"

export default function (
    serviceToCall: Function,  // More specific?
    params: any[],
    allowRun: boolean = true,
    triggerDeps: any = [],
    errorMessage?: string,
    optionalParams: any[] = []
): IResult {
    const navigate = useNavigate();

    const msalContext = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const [loading, setLoading] = useState<boolean | null>(null);
    const [error, setError] = useState<boolean | null>(null);
    const [errMessage, setErrMessage] = useState('');
    const [results, setResults] = useState<Results>(null);

    const { setErr, removeErr } = useAPIError();

    useEffect(() => {
        if (!allowRun) { return }
        
        setLoading(true);
        setError(false);
        setErrMessage('');
        serviceToCall(...params, { msalContext, isAuthenticated }, ...optionalParams ).then((resp: any) => {
            setResults(resp);
            setLoading(false);
        }).catch((err: Error) => {
            setLoading(false);
            setError(true);
            setResults([]);
            // This is a temporary measure to display a custom error message in a single scenario. Once strategy has
            // been agreed we will change this in the future
            if (err.message == noChargebeeCustomerFoundMessage){
                errorMessage = err.message
            }
            else if (!errorMessage){
                errorMessage = defaultErrorMessage
            }
            setErrMessage(errorMessage)
            setErr(
                true,
                {
                    msg: `Error in ${serviceToCall.name}.`,
                    // If there is an error from the backend, upon click reset err state and redirect user to homepage
                    UI: <WarningModal errorMessage={errorMessage} onClickClose={() => { removeErr(); navigate('/'); }} />
                });
        });
    }, triggerDeps);

    return [loading, error, errMessage, results];
};


const WarningModal = ({ onClickClose, errorMessage }: { onClickClose: React.MouseEventHandler, errorMessage: string }) => (
    <Warning
        message="Something went wrong"
        additionalInfo={errorMessage}
        onClickClose={onClickClose}
    />
);

