import axios, { AxiosRequestConfig } from 'axios';
import { backendURL, requestTimeout } from '@utils/constants/axiosConfig';
import { PlanPayload } from '@views/journeys/OnboardingFormPayloadFactory';


export type Headers = {
    [headerName: string]: string | number
};


export type QueryParam = {
    [paramName: string]: string | number
};


export type Payload = {
    [paramName: string]: string | number | boolean | object
} | PlanPayload;


/**
 * Object comprising basic configuration to provide to Axios
 * - baseURL: represents the Host section from a URL
 * - headers:
 *    - encoding specification: JSON
 * - responseType: configured to be a JavaScript object created by parsing the contents of received data as JSON
 * - timeout: set default to 20 seconds
 */
const basicConfig: AxiosRequestConfig = {
    baseURL: backendURL,
    headers: {
        'Content-Type': 'application/json',
    },
    responseType: 'json',
    timeout: requestTimeout * 1000,
};


/**
 * Wrapper function over Axios's GET. It is provided by default with a copy of a basic config, which contains the Host URL,
 * encoding specs (JSON), a default timeout and security header. Any of them can be overridden but still according to what
 * Axios config fields expects (as a matter of data type).
 *
 * The caller is free to provide an absolute URL for the 'path' param. In this case, config's field representing the Host URL
 * is ignored, so this function allows the freedom on choosing to use a default config or custom, on demand required
 * absolute URL (which can represent a whole different Host URL/backend).
 *
 * Free to raise an exception for a failed request. Error handling done at an upper level, by the code calling this function,
 * allowing each error to be treated specifically if needed. Please check basicConfig and axios docs to establish under what
 * conditions an exception is raised.
 *
 * @param path can be either an absolute path or relative path. In case of relative paths, it will be added to the baseURL. Differentiation between the two cases made by axios, underneath.
 * @param params query params to be added to the URL
 * @param headers extra headers to be added besides the default ones
 * @returns success or error result of the request
 */
export async function get(
    path: string,
    params?: QueryParam,
    headers?: Headers,
): Promise<any> {
    let cfg = { ...basicConfig };  // local copy of config
    cfg.headers = { ...cfg.headers, ...headers };  // adding custom headers
    cfg.params = { ...cfg.params, ...params };  // adding query params

    return await axios.get(path, cfg);
};


/**
 * Wrapper function over Axios's POST. It is provided by default with a copy of a basic config, which contains the Host URL,
 * encoding specs (JSON), a default timeout and security header. Any of them can be overridden but still according to what
 * Axios config fields expects (as a matter of data type).
 *
 * The caller is free to provide an absolute URL for the 'path' param. In this case, config's field representing the Host URL
 * is ignored, so this function allows the freedom on choosing to use a default config or custom, on demand required
 * absolute URL (which can represent a whole different Host URL/backend).
 *
 * Free to raise an exception for a failed request. Error handling done at an upper level, by the code calling this function,
 * allowing each error to be treated specifically if needed. Please check basicConfig and axios docs to establish under what
 * conditions an exception is raised.
 *
 * @param path can be either an absolute path or relative path. In case of relative paths, it will be added to the baseURL. Differentiation between the two made by axios, underneath.
 * @param payload object having a JSON structure, representing the body of the request
 * @param headers extra headers to be added besides the default ones. If any provided header was already existing inside the basic config, it will be overridden with the value provided inside the call.
 * @returns success or error result of the request
 */
export async function post(
    path: string,
    params?: QueryParam,
    payload?: Payload,
    headers?: Headers
): Promise<any> {
    let cfg = { ...basicConfig };  // local copy of config
    cfg.headers = { ...cfg.headers, ...headers };  // adding custom headers
    cfg.params = { ...cfg.params, ...params };  // adding query params

    return await axios.post(path, payload, cfg);
};


/**
 * Free to raise an exception for a failed request. Error handling done at an upper level, by the code calling this function,
 * allowing each error to be treated specifically if needed. Please check basicConfig and axios docs to establish under what
 * conditions an exception is raised.
 *
 * @param path can be either an absolute path or relative path. In case of relative paths, it will be added to the baseURL. Differentiation between the two made by axios, underneath.
 * @param params query params to be added to the URL
 * @param payload object having a JSON structure, representing the body of the request
 * @param headers extra headers to be added besides the default ones. If any provided header was already existing inside the basic config, it will be overridden with the value provided inside the call.
 * @returns success or error result of the request
 */
export async function put(
    path: string,
    params?: QueryParam,
    payload?: Payload,
    headers?: Headers
): Promise<any> {
    let cfg = { ...basicConfig };  // local copy of config
    cfg.headers = { ...cfg.headers, ...headers };  // adding custom headers
    cfg.params = { ...cfg.params, ...params };  // adding query params

    return await axios.put(path, payload, cfg);
};


/**
 * Free to raise an exception for a failed request. Error handling done at an upper level, by the code calling this function,
 * allowing each error to be treated specifically if needed. Please check basicConfig and axios docs to establish under what
 * conditions an exception is raised.
 *
 * @param path can be either an absolute path or relative path. In case of relative paths, it will be added to the baseURL. Differentiation between the two made by axios, underneath.
 * @param params query params to be added to the URL
 * @param headers extra headers to be added besides the default ones. If any provided header was already existing inside the basic config, it will be overridden with the value provided inside the call.
 * @returns success or error result of the request
 */
export async function delete_(
    path: string,
    params?: QueryParam,
    headers?: Headers
): Promise<any> {
    let cfg = { ...basicConfig };  // local copy of config
    cfg.headers = { ...cfg.headers, ...headers };  // adding custom headers
    cfg.params = { ...cfg.params, ...params };  // adding query params

    return await axios.delete(path, cfg);
};
