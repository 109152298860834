import {useUserJourneyContext} from '@context/UserJourneyContext';
import React, {useEffect} from 'react';

import {BreadcrumbProps} from 'SmartOpsHome/breadcrumbObject';


/**
 * View intended to be integrated into the Flexi Own  (+ used) onboarding form.
 *
 * All inputs are stored into the corresponding section of the User context.
 *
 */
export default function NSWEVSubscriptions({breadcrumbObject}: BreadcrumbProps) {

    const {formState, updateFormState} = useUserJourneyContext();
    const {contractDetails} = formState;


    const containerStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        height: '50vh', // need to set dynamically
    };

    const rowStyle: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
    };

    const itemStyle: React.CSSProperties = {
        flex: 1,
        padding: '10px',
        boxSizing: 'border-box',
    };

    const textStyle: React.CSSProperties = {
        ...itemStyle,
        fontWeight: 'bold',
    };

    const numberStyle: React.CSSProperties = {
        ...itemStyle,
        textAlign: 'right',
    };


    useEffect(() => {
        breadcrumbObject['NSW EV Subscriptions']['Weekly Subs without Incentive'] = contractDetails.NSWIncentivePlanBeforeDiscount;
        breadcrumbObject['NSW EV Subscriptions']['NSW Goverment Incentive'] = contractDetails.NSWIncentiveTotalValue;
        breadcrumbObject['NSW EV Subscriptions']['Weekly Subs with Incentive'] = contractDetails.planWeeklyFee;
    }, [contractDetails])

    return (
        <><p style={{margin: '2.5vh 0'}}> NSW EV Subscription Only: As a successful recipient of the 'Round 3 NSW EV
            Fleet Incentive', Splend is able to offer our customers a discounted rate on their weekly EV subscription.
        </p>
            <p style={{margin: '2.5vh 0'}}>
                The following information is from the selected Chargebee Plan. Please confirm the details before
                submitting as they are required to submit a claim to the NSW government.
            </p>
            <div style={{margin: '2.5vh 0'}}>
                <div style={rowStyle}>
                    <div style={textStyle}>Weekly subscription without incentive ($)</div>
                    <div style={numberStyle}>{contractDetails.NSWIncentivePlanBeforeDiscount}</div>
                </div>

                <div style={rowStyle}>
                    <div style={textStyle}>NSW government EV Fleet incentive total value($)</div>
                    <div style={numberStyle}>{contractDetails.NSWIncentiveTotalValue}</div>
                </div>

                <div style={rowStyle}>
                    <div style={textStyle}>Weekly subscription with incentive but excluding further discounts ($)</div>
                    <div style={numberStyle}>{contractDetails.planWeeklyFee}</div>
                </div>
            </div>

        </>
    );
}
