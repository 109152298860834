import { getWithIDToken, IMSTokenParams } from "@utils/http/MSAuthAPI";
import { Country } from '@utils/constants/localisation';

export interface ICardPaymentMethod {
    id: string,
    brand: string,
    last4: string,
    expiry_month: number,
    expiry_year: number,
    primary: boolean,
    backup: boolean
}
interface IPaymentMethodService {
    getCustomerCardPaymentMethod(customerId: string, country: Country, firstName: string, lastName: string, email: string, IDTokenParams: IMSTokenParams, includeOnlyPrimary?: boolean): Promise<ICardPaymentMethod[]>
}
const PaymentMethodService: IPaymentMethodService = {
    async getCustomerCardPaymentMethod(
        customerId: string, 
        country: Country,
        firstName: string,
        lastName: string,
        email: string,
        IDTokenParams: IMSTokenParams, 
        includeOnlyPrimary? : boolean): Promise<ICardPaymentMethod[]> {
        const params: { customer_id: string, country: string, include_only_primary?: string, first_name?: string, last_name?: string, customer_email?: string } = { customer_id: customerId, country: country, first_name: firstName, last_name: lastName, customer_email: email };
        includeOnlyPrimary ? params.include_only_primary = '' : undefined;
                
        const resp = await getWithIDToken(
            `v1/customers/${customerId}/card-payment-methods`,
            IDTokenParams,
            params
        );

        if (resp.status === 500) {
            throw new Error('Server error');
        }

        return resp.data;
    }
}
export default PaymentMethodService;