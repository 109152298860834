import React, { ChangeEvent, CSSProperties, useEffect, useState } from 'react';

import { UserFormStates, useUserJourneyContext } from '@context/UserJourneyContext';
import { PanelItem } from '@components/panels/PanelItems';
import { IPlan, PlanService } from '@services/SubscriptionPlans';
import { ContactOwnersService } from '@services/ContactOwners';
import Label from '@components/inputs/text/Label';
import DatePicker from '@components/inputs/DateInput';
import DropDownMenu, { IChoice } from '@components/inputs/DropDownMenu';
import useServiceHook from '@hooks/useServiceHook';
import Elliptical from '@components/spinners/Elliptical/Elliptical';
import { textBoxStyle } from '@utils/styles/textboxStyle';
import { BreadcrumbProps } from 'SmartOpsHome/breadcrumbObject';
import {
    addNextYearsWeeks,
    countrySpecificCurrency,
    countrySpecificDistanceUnit,
    dateToISOFormat,
    getTimeDifference,
    keepDigits,
    emailMinusSplendDomain
} from '@utils/misc/functions';


export default function AgreementSchedule({ breadcrumbObject }: BreadcrumbProps) {
    const todaysDate = dateToISOFormat(new Date());  // YYYY-mm-dd
    const dropDownPleaseSelect = { label: '- please select -', value: '', enabled: false };
    const defaultSetupFee = "990";

    const { formState, updateFormState } = useUserJourneyContext();
    const { driverDetails, contractDetails, carInfo } = formState;
    const [setupFee, setSetupFee] = useState<string>(contractDetails.planSetupFee != null ? contractDetails.planSetupFee : defaultSetupFee);
    const [executionDate, setExecutionDate] = useState<string>(contractDetails.executionDate || todaysDate);
    const [subsStartDate, setSubsStartDate] = useState<string>(contractDetails.subsStartDate || todaysDate);

    // Set planLen state with the value from context if there is one.
    const planLengths = [
        { label: '4', value: '4', enabled: true },
        { label: '5', value: '5', enabled: true }
    ];
    const [planLen, setPlanLen] = useState<number>(contractDetails.planLen);

    const [allowSearch, setAllowSearch] = useState<boolean>(false);

    const kmAllowanceList = [
        { label: '1000', value: '1000', enabled: true },
        { label: '1200', value: '1200', enabled: true }
    ];
    const [kmAllowance, setKmAllowance] = useState<String>('');

    const kmExcessFeeList = [
        { label: '0.10', value: '0.10', enabled: true },
        { label: '0.20', value: '0.20', enabled: true },
        { label: 'N/A', value: 'N/A', enabled: true }
    ];
    const [kmExcessFee, setKmExcessFee] = useState<String>('');

    const defaultDistanceAllowance = kmAllowanceList[0];
    const defaultExcessFee = kmExcessFeeList[0];

    const defaultPlanLen = planLen
        ? planLengths.filter(item => Number(item.value) === planLen)[0]
        : dropDownPleaseSelect;

    // Set default value for the insurance from context, if there is one.
    const insuranceOptions = [
        { label: 'To be organised by Splend', value: 'Splend', enabled: true },
        { label: 'To be organised by Hirer', value: 'Hirer', enabled: true }
    ];
    const defaultInsurance = insuranceOptions.find(item => item.value === contractDetails.insuranceCover) || insuranceOptions[0];

    // If a plan is found in the context, set it as default, being the user's last selection
    let defaultPlan = contractDetails.planId
        ? { label: contractDetails.planName, value: contractDetails.planId, enabled: true }
        : undefined;

    // When plan length changes, fire backend request to get corresponding plans. It shall
    // happen only if plan length is not 0, being the reason for the condition set below,
    // representing the hook's allow-to-run condition. So that acts like a on-off switch,
    // and the dependencies list (coincidentally here the same var) acts like a trigger.
    const [loading, , , availablePlans] = useServiceHook(
        PlanService.getPlans,
        [{ plan_type: driverDetails.plan, state: carInfo.hub, term: planLen, weekly_distance_allowance: kmAllowance, excess_fee: kmExcessFee }, driverDetails.country],  // carInfo.hub plays the role of state
        allowSearch == true,
        [allowSearch, planLen, kmAllowance, kmExcessFee]
    );

    //Retrieves all available zoho users for each country. 
    const [userLoading, , , availableContactOwners] = useServiceHook(
        ContactOwnersService.getContactOwners,
        [driverDetails.country],
        true,
    );

    let dropdown = [];
    let dropdownDefault: Partial<IChoice | undefined> = undefined;
    let dropdownDefaultID: string = "";

    // set default contact owner from availableContactOwner list
    if (!loading && availableContactOwners) {
        const defaultContactOwnerIndex = availableContactOwners.findIndex((index: any) =>
            emailMinusSplendDomain(index.email) === emailMinusSplendDomain(contractDetails.contactOwnerEmail));
        const selectedContact = availableContactOwners[defaultContactOwnerIndex];
        //TODO what if no selected contact
        dropdownDefaultID = selectedContact.id
        dropdownDefault = {label: selectedContact.label, value: selectedContact.id, enabled: true}
        dropdown = availableContactOwners.map((item: any) => ({
            value: item.id,
            label: item.label,
            enabled: true
        }));
    }

    // TODO: find a way to update customer email on contact owner change. Currently we have no use for it but we should aim to keep values consistent
    // with selected contact owner
    const onContactOwnerChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        updateFormState(
            UserFormStates.contractDetails,
            { contactOwner: e.currentTarget.options[e.currentTarget.selectedIndex].label, contactOwnerId: e.currentTarget.value }
        );
    };

    useEffect(() => { // runs only at first page render
        // if no executionDate, take it from state, which should've been set to today
        contractDetails.executionDate === '' &&
            updateFormState(UserFormStates.contractDetails, { executionDate });

        // if no subsStartDate, take it from state, which should've been set to today
        contractDetails.subsStartDate === '' &&
            updateFormState(UserFormStates.contractDetails, { subsStartDate });

        updateFormState(UserFormStates.contractDetails, { planSetupFee: setupFee });

        setKmAllowance(defaultDistanceAllowance.value);
        setKmExcessFee(defaultExcessFee.value);
    }, []);

    useEffect(  // update context's subsStartDate field upon state update
        () => updateFormState(UserFormStates.contractDetails, { subsStartDate }),
        [subsStartDate]
    );

    useEffect(  // update context's executionDate field upon state update
        () => updateFormState(UserFormStates.contractDetails, { executionDate }),
        [executionDate]
    );

    useEffect(  // update context's planSetupFee when user types new value for setup fee
        () => updateFormState(UserFormStates.contractDetails, { planSetupFee: setupFee }),
        [setupFee]
    );

    // Runs on page re-render. In some cases the defaultInsurance value is the same as the one from the context,
    // in which case basically nothing happens. However, this call is necessary for when the user lands on this
    // page for the first time and there's no default in context, hence the need to put one in there - that must
    // be linked with the default selection from the drop down on the form (below).
    useEffect(() => updateFormState(UserFormStates.contractDetails, { insuranceCover: defaultInsurance.value }), []);

    useEffect(() => {  // when planLen or subsStartDate changes, update the subsEndDate accordingly
        if (planLen === 0 || subsStartDate === '') return;
        const startDate = new Date(subsStartDate);
        const endDate = addNextYearsWeeks(startDate, planLen);
        updateFormState(UserFormStates.contractDetails, { subsEndDate: dateToISOFormat(endDate) });
    }, [planLen, subsStartDate]);

    const onPlanLenChange = (event: ChangeEvent<HTMLSelectElement>) => {
        // When plan length changes, update the context with the new value
        // and reset the selected plan from the context, since a new list
        // will be returned by the, what would be at that time, an ongoing
        // backend call, and the user will have to choose from new values
        updateFormState(UserFormStates.contractDetails, { planLen: Number(event.target.value) });
        setPlanLen(Number(event.target.value));
        updateFormState(UserFormStates.contractDetails, {
            planId: '',
            planName: '',
            planWeeklyFee: 0,
            // planSetupFee can be reset via state update below
            planMinimumPeriod: 0,
            planWeeklyDistAllowance: 0,
            planAdditionalDistCharge: 0,
        });

        // Used to set the default contact owner id. Plan length must always be selected to continue moving through
        // the form. There doesn't seem to be a way to set the default dropdownID without triggering an infinite loop
        // of rerenders. Therefore, setting the default has been moved here as this will always and only be selected.
        if (contractDetails.contactOwnerId === "") {
            updateFormState(UserFormStates.contractDetails, { contactOwnerId: dropdownDefaultID });
        }
        // setSetupFee(defaultSetupFee);  // de-comment if setupFee needs to be reset at every planLen change
    };

    const onPlanChange = (e: ChangeEvent<HTMLSelectElement>) => {
        // When plan changes, update the context. Stored value will be used
        // if the user moves back and forth through the step, so at later
        // moment, when back on this page, that value is used as default 
        const selectedPlan = availablePlans.filter((item: IPlan) => item.id === e.target.value)[0];
        updateFormState(
            UserFormStates.contractDetails,
            {
                planId: selectedPlan.id,
                planName: selectedPlan.name,
                planWeeklyFee: Number(selectedPlan.weeklyHireFee.toFixed(2)),
                // planSetupFee can be reset via state update below
                planMinimumPeriod: selectedPlan.minimumPeriodWeeks,
                planWeeklyDistAllowance: selectedPlan.weeklyDistAllowance,
                planAdditionalDistCharge: selectedPlan.additionalDistCostPerUnit,
            }
        );
        // Now that the values are sent back as strings these values are excluded from the list of potential updates as they
        // would trigger the mergesend and try to insert the NSW EV addendum into all contracts.
        if (selectedPlan.NSWIncentiveTotalValue && !['N/A', null].includes(selectedPlan.NSWIncentiveTotalValue)) {
           updateFormState(UserFormStates.contractDetails, {NSWIncentiveTotalValue: selectedPlan.NSWIncentiveTotalValue})
        }
        else {
            updateFormState(UserFormStates.contractDetails, {NSWIncentiveTotalValue: ''})
        }
        
        if (selectedPlan.NSWIncentivePlanBeforeDiscount && !['N/A', null].includes(selectedPlan.NSWIncentivePlanBeforeDiscount)) {
            updateFormState(UserFormStates.contractDetails, {NSWIncentivePlanBeforeDiscount: selectedPlan.NSWIncentivePlanBeforeDiscount})
        }
        else {
            updateFormState(UserFormStates.contractDetails, {NSWIncentivePlanBeforeDiscount: ''})
        }
    };

    const onKMAllowanceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setKmAllowance(event.target.value)
        updateFormState(UserFormStates.contractDetails, {
            planId: '',
            planName: '',
            planWeeklyFee: 0,
            planMinimumPeriod: 0,
            planWeeklyDistAllowance: 0,
            planAdditionalDistCharge: 0,
        });
    };

    const onExcessDistanceChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setKmExcessFee(event.target.value)
        updateFormState(UserFormStates.contractDetails, {
            planId: '',
            planName: '',
            planWeeklyFee: 0,
            planMinimumPeriod: 0,
            planWeeklyDistAllowance: 0,
            planAdditionalDistCharge: 0,
        });
    };

    const handleSetupFeeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSetupFee(keepDigits(event.target.value))
    };

    const checkAllFiltersArePopulated = () => setAllowSearch(planLen != 0);

    useEffect(() => {
        checkAllFiltersArePopulated();
    }, [planLen, kmAllowance, kmExcessFee]);


    useEffect(() => {
        breadcrumbObject['Agreement schedule']['Execution Date'] = contractDetails.executionDate;
        breadcrumbObject['Agreement schedule']['Subscription Start Date'] = contractDetails.subsStartDate;
        breadcrumbObject['Agreement schedule']['Plan Length'] = contractDetails.planLen.toString();
        breadcrumbObject['Agreement schedule']['Chargebee Plan'] = contractDetails.planName;
        breadcrumbObject['Agreement schedule']['Total Time Spent on Journey'] = getTimeDifference(breadcrumbObject['Vehicle assignment']['Customer Started Journey'], new Date());
    }, [contractDetails]);

    const divStyles: CSSProperties = {
        margin: '2.5vh 0vw',
    };

    return (<>
        <div style={divStyles}>
            <p style={{ margin: '2.5vh 0' }}>Please ensure the key terms for the customer are correct.</p>
            <Label text='Contact Owner/Authorised Signatory' requiredFieldInd={true} />
            {userLoading === false || userLoading === null
                ? <DropDownMenu
                    menuName={'contactOwnerDropMenu'}
                    defaultVal={dropdownDefault}
                    required={true}
                    choices={dropdown //&& defaultContactOwner && availableContactOwners.includes(defaultContactOwner)
                        // ? [dropdown]
                        // : [...(dropdown || []), dropdownDefault]
                    }
                    onSelect={(event: ChangeEvent<HTMLSelectElement>) => onContactOwnerChange(event)}
                />
                : <Elliptical />
            }
        </div>
        <div style={divStyles}>
            <Label text='Contract execution date' requiredFieldInd={true} />
            <DatePicker
                name='contractExeDate'
                defaultVal={executionDate}
                min={todaysDate}
                required={true}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setExecutionDate(e.currentTarget.value)}
            />
        </div>
        <div style={divStyles}>
            <Label text='Subscription start date' requiredFieldInd={true} />
            <DatePicker
                name='contractStartDate'
                defaultVal={subsStartDate}
                min={todaysDate}
                required={true}
                onChange={(e: ChangeEvent<HTMLSelectElement>) => setSubsStartDate(e.currentTarget.value)}
            />
        </div>
        <div style={divStyles}>
            <Label text='Plan length (years)' requiredFieldInd={true} />
            <DropDownMenu
                menuName='planLenDropMenu'
                defaultVal={defaultPlanLen}
                required={true}
                choices={[dropDownPleaseSelect].concat(planLengths)}
                onSelect={(e: ChangeEvent<HTMLSelectElement>) => onPlanLenChange(e)}
                testId={'plan-length-dropdown'}
            />
        </div>
        <div style={divStyles}>
            <Label text='KM Allowance' requiredFieldInd={true} />
            <DropDownMenu
                menuName='kmAllowanceDropMenu'
                defaultVal={defaultDistanceAllowance}
                required={true}
                choices={[dropDownPleaseSelect].concat(kmAllowanceList)}
                onSelect={(e: ChangeEvent<HTMLSelectElement>) => onKMAllowanceChange(e)}
            />
        </div>
        <div style={divStyles}>
            <Label text={`KM excess fee (${countrySpecificCurrency(driverDetails.country)})`} requiredFieldInd={true} />
            <DropDownMenu
                menuName='kmExcessFeeDropMenu'
                defaultVal={defaultExcessFee}
                required={true}
                choices={[dropDownPleaseSelect].concat(kmExcessFeeList)}
                onSelect={(e: ChangeEvent<HTMLSelectElement>) => onExcessDistanceChange(e)}
            />
        </div>
        <div style={divStyles} >
            <Label text='Plan' requiredFieldInd={true} />
            {loading === false || loading === null
                ? <DropDownMenu
                    menuName='planDropMenu'
                    defaultVal={defaultPlan}
                    required={true}
                    testId='CB-plan-dropdown'
                    choices={availablePlans
                        ? availablePlans.map((item: any) => ({ label: item.name, value: item.id, enabled: true }))
                        : []
                    }
                    onSelect={(e: ChangeEvent<HTMLSelectElement>) => onPlanChange(e)}
                />
                : <div style={{ height: '5vh' }}><Elliptical /></div>
            }
        </div>
        <div style={{ margin: '2.5vh 0vw' }}>
            <PanelItem
                header='Minimum period (weeks)' headerTextStyleCfg={{ bold: true }}
                value={contractDetails.planMinimumPeriod || ''} />
            <PanelItem
                header={`Weekly hire fee (${countrySpecificCurrency(driverDetails.country)})`} headerTextStyleCfg={{ bold: true }}
                value={contractDetails.planWeeklyFee || ''} />
            <PanelItem
                header={`Weekly distance allowance (${countrySpecificDistanceUnit(driverDetails.country)})`} headerTextStyleCfg={{ bold: true }}
                value={contractDetails.planWeeklyDistAllowance || ''} />
            <PanelItem
                header={`Additional km charge (${countrySpecificCurrency(driverDetails.country)})`} headerTextStyleCfg={{ bold: true }}
                value={contractDetails.planAdditionalDistCharge || ''} />
        </div>
        <div style={divStyles}>
            <Label text={`Setup fee (${countrySpecificCurrency(driverDetails.country)})`} requiredFieldInd={true} />
            <input
                type='text'
                style={textBoxStyle}
                name='fee'
                value={setupFee}
                required={true}
                onChange={(e: ChangeEvent<HTMLInputElement>) => handleSetupFeeInput(e)}
            />
        </div>
        <div style={divStyles}>
            <Label text='Comprehensive car insurance' />
            <DropDownMenu
                menuName='planLenDropMenu'
                defaultVal={defaultInsurance}
                required={true}
                choices={insuranceOptions}
                onSelect={(e: ChangeEvent<HTMLSelectElement>) =>
                    updateFormState(UserFormStates.contractDetails, { insuranceCover: e.currentTarget.value })}
            />
        </div>
    </>);
};
