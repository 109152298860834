import {
    AddressInfo,
    CarInfo,
    ContractDetails,
    DriverDetails,
    EmergencyContactInfo,
    PersonalInfo,
    SplendInitiatives,
    UsedVehicleAddendumDetails
} from "@context/UserJourneyContext";
import {PlanOptions} from "@utils/planOptions";
import { flipDateFormat, currentTime_HHMM, endOfDayTime_HHMM } from "@utils/misc/functions";


const convertDateForZohoSign = (date: string) => {
    return new Date(date).toLocaleDateString('en-GB', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    })
};


export class PlanPayload {
    prefill_data: any;
    contact_owner_id: string | undefined;
    execution_date: string | undefined;
    licence_expiry: string | undefined;
    contact_id: string | undefined;
    first_name: string | undefined;
    middle_name: string | undefined;
    last_name: string | undefined;
    street: string | undefined;
    postcode: string | undefined;
    province: string | undefined;
    end_date: string | undefined;
    plan: string | undefined;
    start_date: string | undefined;
    dob: string | undefined;
    city: string | undefined;
    vehicle_id: string | undefined;
    national_insurance_number: string | undefined;
    pco_licence_number: string | undefined;
    pco_licence_expiry: string | undefined;
    vehicle_location: string | undefined;
    ipb_discount_amount: number | undefined;

    constructor(driverDetails: any, personalInfo: any, carInfo: any, contractDetails: any, addressInfo: any) {
        this.prefill_data = {
            field_text_data: {
                plan: driverDetails.plan,
                hirer: `${personalInfo.firstName}${personalInfo.middleName ? ' ' + personalInfo.middleName : ''} ${personalInfo.lastName}`,
                vehicle: `${carInfo.year} ${carInfo.make} ${carInfo.model} VIN: ${carInfo.vin} Registration: ${carInfo.registrationPlate}`,
                fleetio_id: carInfo.id,
                vin: carInfo.vin,
                registration: carInfo.registrationPlate,
                year: carInfo.year,
                make: carInfo.make,
                model: carInfo.model,
                colour: carInfo.colour,
                body: carInfo.bodyType,
                odometer: String(carInfo.odometer),
                driver_licence_number: driverDetails.driversLicenceNumber,
                licence_state: driverDetails.province,
                email: personalInfo.email,
            },
            field_date_data: {
                licence_expiry: convertDateForZohoSign(driverDetails.driversLicenceExpiry),
                dob: convertDateForZohoSign(driverDetails.dateOfBirth),
                start_date: convertDateForZohoSign(contractDetails.subsStartDate),
                execution_date: convertDateForZohoSign(contractDetails.executionDate),
            },
            field_boolean_data: {}
        };
        this.plan = driverDetails.plan;
        this.start_date = contractDetails.subsStartDate;
        this.dob = driverDetails.dateOfBirth;
        this.vehicle_id = carInfo.id;
        this.city = addressInfo.city;
        this.execution_date = contractDetails.executionDate;
        this.licence_expiry = driverDetails.driversLicenceExpiry;
        this.contact_id = driverDetails.crmId;
        this.first_name = personalInfo.firstName;
        this.middle_name = personalInfo.middleName;
        this.last_name = personalInfo.lastName;
        this.street = addressInfo.street;
        this.postcode = addressInfo.postcode;
        this.province = driverDetails.province;

        let textData = this.prefill_data.field_text_data;

        if (personalInfo.alternativeName) {
            textData.alternative_name = personalInfo.alternativeName;
        }
    }
};


class RAVCreditHirePayload extends PlanPayload {

    constructor(driverDetails: any, personalInfo: any, carInfo: any, contractDetails: any, addressInfo: any) {
        super(driverDetails, personalInfo, carInfo, contractDetails, addressInfo);

        let textData = this.prefill_data.field_text_data;

        textData.fleetio_id = carInfo.id;
        textData.daily_fee = contractDetails.planDailyFee;
        textData.weekly_allowance = contractDetails.planWeeklyDistAllowance;
        textData.weekly_fee = contractDetails.planWeeklyFee;
        textData.weekly_km_charge = contractDetails.planAdditionalDistCharge;
        textData.agreement_title = `${this.plan} Agreement`;
        textData.vehicle_assignment_title = `${this.plan} Assignment Form`;
        textData.licence_expiry_text = flipDateFormat(driverDetails.driversLicenceExpiry);
        textData.dob_text = flipDateFormat(driverDetails.dateOfBirth);
        textData.start_date_text = flipDateFormat(contractDetails.subsStartDate);
    }
};


class RAVSharecoverPayload extends PlanPayload {
    constructor(driverDetails: any, personalInfo: any, carInfo: any, contractDetails: any, addressInfo: any) {
        super(driverDetails, personalInfo, carInfo, contractDetails, addressInfo);

        let textData = this.prefill_data.field_text_data;

        textData.fleetio_id = carInfo.id;
        textData.daily_fee = contractDetails.planDailyFee;
        textData.weekly_allowance = contractDetails.planWeeklyDistAllowance;
        textData.weekly_fee = contractDetails.planWeeklyFee;
        textData.weekly_km_charge = contractDetails.planAdditionalDistCharge;
        textData.agreement_title = `${driverDetails.plan} Agreement`;
        textData.vehicle_assignment_title = `${driverDetails.plan} Assignment Form`;
        textData.licence_expiry_text = flipDateFormat(driverDetails.driversLicenceExpiry);
        textData.dob_text = flipDateFormat(driverDetails.dateOfBirth);
        textData.start_date_text = flipDateFormat(contractDetails.subsStartDate);
    }
};


class RAVShortTermRentalPayload extends PlanPayload {
    constructor(driverDetails: any, personalInfo: any, carInfo: any, contractDetails: any, addressInfo: any) {
        super(driverDetails, personalInfo, carInfo, contractDetails, addressInfo);

        let textData = this.prefill_data.field_text_data;

        textData.abn = personalInfo.hirerABN;
        textData.minimum_term = contractDetails.planMinimumDailyPeriod;
        textData.weekly_fee = contractDetails.planWeeklyFee;
        textData.weekly_allowance = contractDetails.planWeeklyDistAllowance;
        textData.weekly_km_charge = contractDetails.planAdditionalDistCharge;
        textData.gender = personalInfo.gender;
        textData.nationality = personalInfo.nationality;
        textData.mobile = personalInfo.mobile;
        textData.address = `${addressInfo.street}, ${addressInfo.city}, ${addressInfo.state}, ${addressInfo.postcode}`;
        textData.licence_expiry_text = flipDateFormat(driverDetails.driversLicenceExpiry);
        textData.dob_text = flipDateFormat(driverDetails.dateOfBirth);
        textData.start_date_text = flipDateFormat(contractDetails.subsStartDate);
    }
};


class FlexiPayload extends PlanPayload {

    weekly_kilometre_allowance: any;
    minimum_period_weeks: any;
    splend_initiative: any;
    trip_discount: any;
    available_vehicle_discount: any;

    constructor(driverDetails: any, personalInfo: any, carInfo: any, contractDetails: any, addressInfo: any, emergencyContactInfo: any, splendInitiatives: any) {
        super(driverDetails, personalInfo, carInfo, contractDetails, addressInfo);

        let textData = this.prefill_data.field_text_data;

        // Additional fields for Flexi plans
        textData.weekly_fee = String(contractDetails.planWeeklyFee);
        textData.setup_fee = String(contractDetails.planSetupFee);
        textData.weekly_allowance = String(contractDetails.planWeeklyDistAllowance);
        textData.additional_charge = String(contractDetails.planAdditionalDistCharge);
        textData.cover_by = contractDetails.insuranceCover;
        textData.plan_length = String(contractDetails.planLen);
        textData.contact_owner = contractDetails.contactOwner;
        // TODO we can remove this field as it is no longer part of the onboarding journey.
        //  The field also needs to be deleted from the AU flexi contract
        textData.additional_driver_fee = contractDetails.additionalDriverFee;
        textData.vehicle_description = `${carInfo.year} ${carInfo.make} ${carInfo.model} VIN: ${carInfo.vin} Registration: ${carInfo.registrationPlate}`;
        textData.dob = convertDateForZohoSign(driverDetails.dateOfBirth);
        textData.start_date = convertDateForZohoSign(contractDetails.subsStartDate);
        textData.licence_expiry = convertDateForZohoSign(driverDetails.driversLicenceExpiry);
        textData.minimum_period_weeks = String(contractDetails.planMinimumPeriod);

        // Emergency contact information
        textData.primary_emergency_name = emergencyContactInfo.primaryName;
        textData.primary_emergency_address = emergencyContactInfo.primaryAddress;
        textData.primary_emergency_phone = emergencyContactInfo.primaryPhone;
        textData.primary_emergency_relationship = emergencyContactInfo.primaryRelationship;
        textData.secondary_emergency_name = emergencyContactInfo.secondaryName;
        textData.secondary_emergency_address = emergencyContactInfo.secondaryAddress;
        textData.secondary_emergency_phone = emergencyContactInfo.secondaryPhone;
        textData.secondary_emergency_relationship = emergencyContactInfo.secondaryRelationship;

        // Address information
        textData.address = `${addressInfo.street} ${addressInfo.city} ${addressInfo.state} ${addressInfo.postcode}`;
        textData.gender = personalInfo.gender;
        textData.nationality = personalInfo.nationality;
        textData.toll_tag_number = personalInfo.tollTagNumber;
        textData.toll_tag_account_number = personalInfo.tollTagAccountNumber;
        textData.toll_tag_issuer = personalInfo.tollTagIssuer;
        textData.abn = personalInfo.hirerABN;

        // Date fields
        this.prefill_data.field_date_data.end_date = convertDateForZohoSign(contractDetails.subsEndDate);

        this.province = addressInfo.state;
        this.contact_owner_id = contractDetails.contactOwnerId;
        this.end_date = contractDetails.subsEndDate;

        if (splendInitiatives.splendInitiative === "Ignition") {
            this.splend_initiative = splendInitiatives.splendInitiative;
        }

        if (splendInitiatives.splendInitiative === "Dynamic Pricing") {
            this.splend_initiative = splendInitiatives.splendInitiative;
            textData.trip_count = splendInitiatives.tripCount;
            textData.trip_discount = splendInitiatives.tripDiscount;
            // The two fields below - weekly_distance_... have been introduced to accommodate both AU and UK.
            // Depending on the country for which the journey is done, just one will be used by Zoho Sign and
            // splend backend.
            // The intention is to reach to a common name and to keep just one for the future.
            textData.weekly_distance_allowance_kms = splendInitiatives.weeklyDistanceAllowance;
            textData.weekly_distance_allowance_miles = splendInitiatives.weeklyDistanceAllowance;
            textData.weekly_distance_allowance_discount = splendInitiatives.weeklyDistanceAllowanceDiscount;
            textData.minimum_period_discount = splendInitiatives.minimumPeriodWeeksDiscount;
            textData.available_vehicle = splendInitiatives.vehicleUtilisation;
            textData.available_vehicle_discount = splendInitiatives.availableVehicleDiscount;
            textData.total_weekly_discount = String(splendInitiatives.totalDynamicPricing);
            textData.weekly_fee = String(splendInitiatives.adjustedWeeklyHireFee);
            textData.minimum_period_weeks = splendInitiatives.minimumPeriodWeeks;
        }
    }

};


class SplendFlexiUKPayload extends PlanPayload {

    weekly_kilometre_allowance: any;
    minimum_period_weeks: any;
    splend_initiative: any;
    trip_discount: any;
    available_vehicle_discount: any;

    constructor(
        driverDetails: DriverDetails,
        personalInfo: PersonalInfo,
        carInfo: CarInfo,
        contractDetails: ContractDetails,
        addressInfo: AddressInfo,
        emergencyContactInfo: EmergencyContactInfo,
        splendInitiatives: SplendInitiatives
    ) {
        super(driverDetails, personalInfo, carInfo, contractDetails, addressInfo);

        let textData = this.prefill_data.field_text_data;

        // Additional fields for Flexi plans
        textData.weekly_fee = String(contractDetails.planWeeklyFee);
        textData.setup_fee = String(contractDetails.planSetupFee);
        textData.weekly_allowance = String(contractDetails.planWeeklyDistAllowance);
        textData.additional_charge = String(contractDetails.planAdditionalDistCharge);
        // cover_by may not be needed as contract template seem to not have such field
        textData.cover_by = contractDetails.insuranceCover;
        textData.plan_length = String(contractDetails.planLen);
        textData.contact_owner = contractDetails.contactOwner;
        textData.additional_driver_fee = contractDetails.additionalDriverFee;
        textData.vehicle_description = `${carInfo.year} ${carInfo.make} ${carInfo.model} VIN: ${carInfo.vin} Registration: ${carInfo.registrationPlate}`;
        textData.dob = convertDateForZohoSign(driverDetails.dateOfBirth);
        textData.start_date = `${convertDateForZohoSign(contractDetails.subsStartDate)} ${currentTime_HHMM()}`;
        textData.end_date = `${convertDateForZohoSign(contractDetails.subsEndDate)} ${endOfDayTime_HHMM()}`;
        textData.licence_expiry = convertDateForZohoSign(driverDetails.driversLicenceExpiry);
        textData.minimum_period_weeks = String(contractDetails.planMinimumPeriod);
        textData.employment_status = personalInfo.employmentStatus;
        textData.licence_country = driverDetails.licenceCountry;

        // Emergency contact information
        textData.primary_emergency_name = emergencyContactInfo.primaryName;
        textData.primary_emergency_address = emergencyContactInfo.primaryAddress;
        textData.primary_emergency_phone = emergencyContactInfo.primaryPhone;
        textData.primary_emergency_relationship = emergencyContactInfo.primaryRelationship;
        textData.secondary_emergency_name = emergencyContactInfo.secondaryName;
        textData.secondary_emergency_address = emergencyContactInfo.secondaryAddress;
        textData.secondary_emergency_phone = emergencyContactInfo.secondaryPhone;
        textData.secondary_emergency_relationship = emergencyContactInfo.secondaryRelationship;

        // Address information
        textData.address = `${addressInfo.street} ${addressInfo.city} ${addressInfo.postcode}`;
        textData.gender = personalInfo.gender;
        textData.nationality = personalInfo.nationality;
        textData.time_at_address_years = addressInfo.timeAtAddressYears;
        textData.time_at_address_months = addressInfo.timeAtAddressMonths;


        // Date fields
        this.prefill_data.field_date_data.end_date = convertDateForZohoSign(contractDetails.subsEndDate);

        this.province = addressInfo.state;
        this.contact_owner_id = contractDetails.contactOwnerId;
        this.end_date = contractDetails.subsEndDate;

        if (splendInitiatives.splendInitiative === "Ignition") {
            this.splend_initiative = splendInitiatives.splendInitiative;
            textData.subs_start_date = convertDateForZohoSign(contractDetails.subsStartDate);
            textData.ignition_end_date = convertDateForZohoSign(splendInitiatives.ignitionEndDate);
            textData.ignition_discount = "50"
        }

        if (splendInitiatives.splendInitiative === "Dynamic Pricing") {
            this.splend_initiative = splendInitiatives.splendInitiative;
            textData.trip_count = splendInitiatives.tripCount;
            textData.trip_discount = splendInitiatives.tripDiscount;
            textData.weekly_distance_allowance_kms = splendInitiatives.weeklyDistanceAllowance;
            textData.weekly_distance_allowance_miles = splendInitiatives.weeklyDistanceAllowance;
            textData.weekly_distance_allowance_discount = splendInitiatives.weeklyDistanceAllowanceDiscount;
            textData.minimum_period_discount = splendInitiatives.minimumPeriodWeeksDiscount;
            textData.available_vehicle = splendInitiatives.vehicleUtilisation;
            textData.available_vehicle_discount = splendInitiatives.availableVehicleDiscount;
            textData.total_weekly_discount = String(splendInitiatives.totalDynamicPricing);
            textData.weekly_fee = String(splendInitiatives.adjustedWeeklyHireFee);
            textData.minimum_period_weeks = splendInitiatives.minimumPeriodWeeks;
            textData.driver_rating = splendInitiatives.driverRating; // new
            textData.weekly_allowance = String(splendInitiatives.weeklyDistanceAllowance);
            
        }
        this.national_insurance_number = personalInfo.nationalInsuranceNumber;
        this.pco_licence_number = driverDetails.pcoLicenseNumber;
        this.pco_licence_expiry = driverDetails.pcoLicenceExpiryDate;
        this.vehicle_location = carInfo.hub;
        // todo is this used in zoho sign contracts ?
    }
};


class SplendFlexiOwnPayload extends PlanPayload {
    constructor(driverDetails: any, personalInfo: any, carInfo: any, contractDetails: any, addressInfo: any, emergencyContactInfo: any, usedVehicleAddendum: any) {
        super(driverDetails, personalInfo, carInfo, contractDetails, addressInfo);

        let textData = this.prefill_data.field_text_data;

        textData.end_date_text = flipDateFormat(contractDetails.subsEndDate);
        textData.licence_expiry_text = flipDateFormat(driverDetails.driversLicenceExpiry);
        textData.dob_text = flipDateFormat(driverDetails.dateOfBirth);
        textData.start_date_text = flipDateFormat(contractDetails.subsStartDate);
        textData.execution_date_text = flipDateFormat(contractDetails.executionDate);

        // Adding fields specific to SplendFlexiOwn plan
        textData.vehicle_description = `${carInfo.year} ${carInfo.make} ${carInfo.model} VIN: ${carInfo.vin} Registration: ${carInfo.registrationPlate}`;
        textData.type = driverDetails.plan;
        textData.primary_emergency_name = emergencyContactInfo.primaryName;
        textData.primary_emergency_address = emergencyContactInfo.primaryAddress;
        textData.primary_emergency_phone = emergencyContactInfo.primaryPhone;
        textData.primary_emergency_relationship = emergencyContactInfo.primaryRelationship;
        textData.secondary_emergency_name = emergencyContactInfo.secondaryName;
        textData.secondary_emergency_address = emergencyContactInfo.secondaryAddress;
        textData.secondary_emergency_phone = emergencyContactInfo.secondaryPhone;
        textData.secondary_emergency_relationship = emergencyContactInfo.secondaryRelationship;
        textData.address = `${addressInfo.street} ${addressInfo.city} ${addressInfo.state} ${addressInfo.postcode}`;
        textData.gender = personalInfo.gender;
        textData.nationality = personalInfo.nationality;
        textData.toll_tag_number = personalInfo.tollTagNumber;
        textData.toll_tag_account_number = personalInfo.tollTagAccountNumber;
        textData.toll_tag_issuer = personalInfo.tollTagIssuer;
        textData.abn = personalInfo.hirerABN;
        textData.weekly_fee = String(contractDetails.planWeeklyFee);
        textData.setup_fee = String(contractDetails.planSetupFee);
        textData.weekly_allowance = String(contractDetails.planWeeklyDistAllowance);
        textData.additional_charge = String(contractDetails.planAdditionalDistCharge);
        textData.cover_by = contractDetails.insuranceCover;
        textData.plan_length = String(contractDetails.planLen);
        textData.contact_owner = contractDetails.contactOwner;
        textData.nsw_ev_incentive_value_before_discount = contractDetails.NSWIncentivePlanBeforeDiscount;
        textData.nsw_ev_incentive_total = contractDetails.NSWIncentiveTotalValue;
        textData.minimum_period_weeks = String(contractDetails.planMinimumPeriod);
        textData.time_at_address_years = personalInfo.timeAtAddressYears;
        textData.time_at_address_months = personalInfo.timeAtAddressMonths;
        textData.employment_status = personalInfo.employmentStatus;
        textData.licence_country = driverDetails.licenceCountry;
        // Adding Insurance Price Beat related pre-filled text fields 
        textData.registration = carInfo.registrationPlate;
        textData.ipb_discounted_weekly_subscription = (contractDetails.planWeeklyFee - contractDetails.ipbDiscountAmount) || 0;

        // Adding additional fields directly to the object
        this.contact_owner_id = contractDetails.contactOwnerId;
        this.execution_date = contractDetails.executionDate;
        this.licence_expiry = driverDetails.driversLicenceExpiry;
        this.contact_id = driverDetails.crmId;
        this.first_name = personalInfo.firstName;
        this.middle_name = personalInfo.middleName;
        this.last_name = personalInfo.lastName;
        this.street = addressInfo.street;
        this.postcode = addressInfo.postcode;
        this.province = addressInfo.state;
        this.end_date = contractDetails.subsEndDate;
        this.vehicle_id = carInfo.id;
        this.city = addressInfo.city;
        this.dob = driverDetails.dateOfBirth;
        this.start_date = contractDetails.subsStartDate;
        this.ipb_discount_amount = contractDetails.ipbDiscountAmount;
    }
};


class SplendFlexiOwnUKPayload extends PlanPayload {
    constructor(
        driverDetails: DriverDetails,
        personalInfo: PersonalInfo,
        carInfo: CarInfo,
        contractDetails: ContractDetails,
        addressInfo: AddressInfo,
        emergencyContactInfo: EmergencyContactInfo,
        usedVehicleAddendum: UsedVehicleAddendumDetails
    ) {
        super(driverDetails, personalInfo, carInfo, contractDetails, addressInfo);

        let textData = this.prefill_data.field_text_data;

        this.prefill_data.field_date_data.end_date = convertDateForZohoSign(contractDetails.subsEndDate);

        // Adding fields specific to SplendFlexiOwn plan
        textData.vehicle_description = `${carInfo.year} ${carInfo.make} ${carInfo.model} VIN: ${carInfo.vin} Registration: ${carInfo.registrationPlate}`;
        textData.type = driverDetails.plan;
        textData.primary_emergency_name = emergencyContactInfo.primaryName;
        textData.primary_emergency_address = emergencyContactInfo.primaryAddress;
        textData.primary_emergency_phone = emergencyContactInfo.primaryPhone;
        textData.primary_emergency_relationship = emergencyContactInfo.primaryRelationship;
        textData.secondary_emergency_name = emergencyContactInfo.secondaryName;
        textData.secondary_emergency_address = emergencyContactInfo.secondaryAddress;
        textData.secondary_emergency_phone = emergencyContactInfo.secondaryPhone;
        textData.secondary_emergency_relationship = emergencyContactInfo.secondaryRelationship;
        textData.address = `${addressInfo.street} ${addressInfo.city} ${addressInfo.postcode}`;
        textData.gender = personalInfo.gender;
        textData.nationality = personalInfo.nationality;
        textData.weekly_fee = String(contractDetails.planWeeklyFee);
        textData.setup_fee = String(contractDetails.planSetupFee);
        textData.weekly_allowance = String(contractDetails.planWeeklyDistAllowance);
        textData.additional_charge = String(contractDetails.planAdditionalDistCharge);
        textData.cover_by = contractDetails.insuranceCover;
        textData.plan_length = String(contractDetails.planLen);
        textData.contact_owner = contractDetails.contactOwner;
        textData.minimum_period_weeks = String(contractDetails.planMinimumPeriod);
        textData.licence_expiry_text = flipDateFormat(driverDetails.driversLicenceExpiry);
        textData.dob_text = flipDateFormat(driverDetails.dateOfBirth);
        // Made optional as the directive for UK is that the field in the contract is not required.
        // textData.licence_country = driverDetails.licenceCountry;

        // Adding additional fields directly to the object
        this.contact_owner_id = contractDetails.contactOwnerId;
        this.execution_date = contractDetails.executionDate;
        this.licence_expiry = driverDetails.driversLicenceExpiry;
        this.contact_id = driverDetails.crmId;
        this.first_name = personalInfo.firstName;
        this.middle_name = personalInfo.middleName;
        this.last_name = personalInfo.lastName;
        this.street = addressInfo.street;
        this.postcode = addressInfo.postcode;
        this.province = addressInfo.state;
        this.end_date = contractDetails.subsEndDate;
        this.vehicle_id = carInfo.id;
        this.city = addressInfo.city;
        this.dob = driverDetails.dateOfBirth;
        this.start_date = contractDetails.subsStartDate;
        this.national_insurance_number = personalInfo.nationalInsuranceNumber;
        this.pco_licence_number = driverDetails.pcoLicenseNumber;
        this.pco_licence_expiry = driverDetails.pcoLicenceExpiryDate;
        this.vehicle_location = carInfo.hub;
        // todo is this used in zoho sign contracts ?

    }
};


class SplendRentToOwnUsedVehiclePayload extends PlanPayload {
    constructor(
        driverDetails: DriverDetails,
        personalInfo: PersonalInfo,
        carInfo: CarInfo,
        contractDetails: ContractDetails,
        addressInfo: AddressInfo,
        emergencyContactInfo: EmergencyContactInfo,
        usedVehicleAddendum: UsedVehicleAddendumDetails
    ) {
        super(driverDetails, personalInfo, carInfo, contractDetails, addressInfo);

        let textData = this.prefill_data.field_text_data;

        textData.end_date_text = flipDateFormat(contractDetails.subsEndDate);
        textData.licence_expiry_text = flipDateFormat(driverDetails.driversLicenceExpiry);
        textData.dob_text = flipDateFormat(driverDetails.dateOfBirth);
        textData.start_date_text = flipDateFormat(contractDetails.subsStartDate);
        textData.execution_date_text = flipDateFormat(contractDetails.executionDate);

        // Adding fields specific to SplendRentToOwnUsedVehicle plan
        textData.vehicle_description = `${carInfo.year} ${carInfo.make} ${carInfo.model} VIN: ${carInfo.vin} Registration: ${carInfo.registrationPlate}`;
        textData.type = driverDetails.plan;
        textData.primary_emergency_name = emergencyContactInfo.primaryName;
        textData.primary_emergency_address = emergencyContactInfo.primaryAddress;
        textData.primary_emergency_phone = emergencyContactInfo.primaryPhone;
        textData.primary_emergency_relationship = emergencyContactInfo.primaryRelationship;
        textData.secondary_emergency_name = emergencyContactInfo.secondaryName;
        textData.secondary_emergency_address = emergencyContactInfo.secondaryAddress;
        textData.secondary_emergency_phone = emergencyContactInfo.secondaryPhone;
        textData.secondary_emergency_relationship = emergencyContactInfo.secondaryRelationship;
        textData.address = `${addressInfo.street} ${addressInfo.city} ${addressInfo.state} ${addressInfo.postcode}`;
        textData.gender = personalInfo.gender;
        textData.nationality = personalInfo.nationality;
        textData.toll_tag_number = personalInfo.tollTagNumber;
        textData.toll_tag_account_number = personalInfo.tollTagAccountNumber;
        textData.toll_tag_issuer = personalInfo.tollTagIssuer;
        textData.abn = personalInfo.hirerABN;
        textData.weekly_fee = String(contractDetails.planWeeklyFee);
        textData.setup_fee = String(contractDetails.planSetupFee);
        textData.weekly_allowance = String(contractDetails.planWeeklyDistAllowance);
        textData.additional_charge = String(contractDetails.planAdditionalDistCharge);
        textData.cover_by = contractDetails.insuranceCover;
        textData.plan_length = String(contractDetails.planLen);
        textData.contact_owner = contractDetails.contactOwner;
        textData.nsw_ev_incentive_value_before_discount = contractDetails.NSWIncentivePlanBeforeDiscount;
        textData.nsw_ev_incentive_total = contractDetails.NSWIncentiveTotalValue;

        // Adding fields specific to used vehicle addendum
        textData.reduced_subscription_term = usedVehicleAddendum.reducedSubscriptionTerm;
        textData.discounted_weeks = usedVehicleAddendum.numberOfDiscountedWeeks;
        textData.scheduled_interval_services = usedVehicleAddendum.scheduledIntervalServices;
        textData.replacement_tyres = usedVehicleAddendum.replacementTyres;
        textData.replacement_brake_pads = usedVehicleAddendum.replacementBrakePads;
        textData.changed_brake_discs = usedVehicleAddendum.replacementOrMachined;

        textData.licence_country = driverDetails.licenceCountry;
        textData.minimum_period_weeks = String(contractDetails.planMinimumPeriod);

        this.prefill_data.field_date_data.end_date = convertDateForZohoSign(contractDetails.subsEndDate);

        // Adding additional fields directly to the object
        this.contact_owner_id = contractDetails.contactOwnerId;
        this.province = addressInfo.state;
        this.end_date = contractDetails.subsEndDate;
        this.vehicle_location = carInfo.hub;

    }
};

class SplendRentToOwnUsedVehicleUKPayload extends PlanPayload {
    constructor(
        driverDetails: DriverDetails,
        personalInfo: PersonalInfo,
        carInfo: CarInfo,
        contractDetails: ContractDetails,
        addressInfo: AddressInfo,
        emergencyContactInfo: EmergencyContactInfo,
        usedVehicleAddendum: UsedVehicleAddendumDetails
    ) {
        super(driverDetails, personalInfo, carInfo, contractDetails, addressInfo);

        let textData = this.prefill_data.field_text_data;

        textData.licence_expiry_text = flipDateFormat(driverDetails.driversLicenceExpiry);
        textData.dob_text = flipDateFormat(driverDetails.dateOfBirth);

        // Adding fields specific to SplendRentToOwnUsedVehicle plan
        textData.vehicle_description = `${carInfo.year} ${carInfo.make} ${carInfo.model} VIN: ${carInfo.vin} Registration: ${carInfo.registrationPlate}`;
        textData.type = driverDetails.plan;
        textData.primary_emergency_name = emergencyContactInfo.primaryName;
        textData.primary_emergency_address = emergencyContactInfo.primaryAddress;
        textData.primary_emergency_phone = emergencyContactInfo.primaryPhone;
        textData.primary_emergency_relationship = emergencyContactInfo.primaryRelationship;
        textData.secondary_emergency_name = emergencyContactInfo.secondaryName;
        textData.secondary_emergency_address = emergencyContactInfo.secondaryAddress;
        textData.secondary_emergency_phone = emergencyContactInfo.secondaryPhone;
        textData.secondary_emergency_relationship = emergencyContactInfo.secondaryRelationship;
        textData.address = `${addressInfo.street} ${addressInfo.city} ${addressInfo.postcode}`;
        textData.gender = personalInfo.gender;
        textData.nationality = personalInfo.nationality;
        textData.weekly_fee = String(contractDetails.planWeeklyFee);
        textData.setup_fee = String(contractDetails.planSetupFee);
        textData.weekly_allowance = String(contractDetails.planWeeklyDistAllowance);
        textData.additional_charge = String(contractDetails.planAdditionalDistCharge);
        textData.cover_by = contractDetails.insuranceCover;
        textData.plan_length = String(contractDetails.planLen);
        textData.contact_owner = contractDetails.contactOwner;
        textData.time_at_address_years = addressInfo.timeAtAddressYears;
        textData.time_at_address_months = addressInfo.timeAtAddressMonths;

        // Adding fields specific to used vehicle addendum
        textData.reduced_subscription_term = usedVehicleAddendum.reducedSubscriptionTerm;
        textData.discounted_weeks = usedVehicleAddendum.numberOfDiscountedWeeks;
        textData.scheduled_interval_services = usedVehicleAddendum.scheduledIntervalServices;
        textData.replacement_tyres = usedVehicleAddendum.replacementTyres;
        textData.replacement_brake_pads = usedVehicleAddendum.replacementBrakePads;
        textData.changed_brake_discs = usedVehicleAddendum.replacementOrMachined;
        textData.licence_country = driverDetails.licenceCountry;
        textData.minimum_period_weeks = String(contractDetails.planMinimumPeriod);

        this.prefill_data.field_date_data.end_date = convertDateForZohoSign(contractDetails.subsEndDate);

        // Adding additional fields directly to the object
        this.contact_owner_id = contractDetails.contactOwnerId;
        this.province = addressInfo.state;
        this.end_date = contractDetails.subsEndDate;
        this.vehicle_location = carInfo.hub;
        this.national_insurance_number = personalInfo.nationalInsuranceNumber;
        this.pco_licence_number = driverDetails.pcoLicenseNumber;
        this.pco_licence_expiry = driverDetails.pcoLicenceExpiryDate;

    }
};


type PayloadType =
    | RAVCreditHirePayload
    | FlexiPayload
    | RAVSharecoverPayload
    | RAVShortTermRentalPayload
    | SplendFlexiOwnPayload
    | SplendRentToOwnUsedVehiclePayload;

//Move everything to separate ts file and import create Payload function. That way it can be exported for test
export const createPayload = (
    planType: string,
    driverDetails: any,
    personalInfo: any,
    carInfo: any,
    contractDetails: any,
    addressInfo: any,
    emergencyContactInfo: any = {},
    usedVehicleAddendum: any = {},
    splendInitiatives: any = {},
): PayloadType => {
    switch (planType) {
        case PlanOptions.RAVCreditHire:
            return new RAVCreditHirePayload(driverDetails, personalInfo, carInfo, contractDetails, addressInfo);
        case PlanOptions.SplendFlexi:
            return new FlexiPayload(driverDetails, personalInfo, carInfo, contractDetails, addressInfo, emergencyContactInfo, splendInitiatives);
        case PlanOptions.RAVSharecover:
            return new RAVSharecoverPayload(driverDetails, personalInfo, carInfo, contractDetails, addressInfo);
        case PlanOptions.RAVShortTermRental:
            return new RAVShortTermRentalPayload(driverDetails, personalInfo, carInfo, contractDetails, addressInfo);
        case PlanOptions.SplendFlexiOwn:
            return new SplendFlexiOwnPayload(driverDetails, personalInfo, carInfo, contractDetails, addressInfo, emergencyContactInfo, usedVehicleAddendum);
        case PlanOptions.SplendRentToOwnUsedVehicle:
            return new SplendRentToOwnUsedVehiclePayload(driverDetails, personalInfo, carInfo, contractDetails, addressInfo, emergencyContactInfo, usedVehicleAddendum);
        case PlanOptions.UKFlexi:
            return new SplendFlexiUKPayload(driverDetails, personalInfo, carInfo, contractDetails, addressInfo, emergencyContactInfo, splendInitiatives);
        case PlanOptions.UKFlexiOwn:
            return new SplendFlexiOwnUKPayload(driverDetails, personalInfo, carInfo, contractDetails, addressInfo, emergencyContactInfo, usedVehicleAddendum);
        case PlanOptions.UKRentToOwnUsed:
            return new SplendRentToOwnUsedVehicleUKPayload(driverDetails, personalInfo, carInfo, contractDetails, addressInfo, emergencyContactInfo, usedVehicleAddendum);
        default:
            throw new Error(`Unknown plan type: ${planType}`);
    }
};
