import React, { CSSProperties, ChangeEvent } from "react";

interface IRadioOption {
  label: string;
  id: string;
  value: string;
}

interface IRadioInput {
  radioName: string;
  options: IRadioOption[];
  selectedValue?: string;
  required?: boolean;
  onSelect?: (event: ChangeEvent<HTMLInputElement>) => void;
}

const RadioInput: React.FC<IRadioInput> = ({
  radioName,
  options,
  selectedValue,
  required,
  onSelect,
}) => {
  const defaultStyles: CSSProperties = {
    margin: "0 0 2.5vh 0",
  };
  const labelStyles: CSSProperties = {
    margin: "0 2.5vh 0 0",
  };

  return (
    <div style={defaultStyles}>
      {options.map((item: IRadioOption) => (
        <React.Fragment key={`${radioName}_${item.value}`}>
          <input
            type="radio"
            name={radioName}
            value={item.value}
            id={`${radioName}_${item.value}`}
            required={required}
            checked={selectedValue === item.value}
            onChange={onSelect}
            data-testid={`${radioName}_${item.value}`}
          />
          <span style={labelStyles}>
            {item.label && (
              <label htmlFor={`${radioName}_${item.value}`}>{item.label}</label>
            )}
          </span>
        </React.Fragment>
      ))}
    </div>
  );
};

export default RadioInput;
