import React, { CSSProperties, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";

import useServiceHook from "hooks/useServiceHook";
import { SubscriptionService } from "@services/index";

import SubscriptionsListPanel from '@components/panels/SubscriptionsList/SubscriptionsList';
import { flexiOwnUsedPlanNames, flexiOwnPlanNames, SubscriptionPlan, ravCreditHirePlanNames, ravShareCoverPlanNames, ravShortTermPlanNames } from "@utils/constants/products";
import { NavBar } from "@components/navigation/NavBar/NavBar";
import { ISubscriptionSummaryItem } from "@components/cards/SubscriptionSummary/SubscriptionSummary";
import CircularSpinner from "@components/spinners/Circular/Circular";
import { InfoBox, WarningBox } from '@components/cards/messageBox';
import Button from "@components/buttons/Button/Button";
import { UserFormStates, useUserJourneyContext } from "@context/UserJourneyContext";


export default function OnboardingSubscriptionsList() {
    const navigate = useNavigate();
    const { custId, country } = useParams();

    // Reset carInfo context section if the user exists the
    // RAV onboarding, so when it gets back to VehAssign, he'll
    // have a clean start (for vehicle assignment only).
    const { formState, updateFormState, resetFormState } = useUserJourneyContext();
    useEffect(
        () => {
            resetFormState(UserFormStates.carInfo);
            updateFormState(UserFormStates.driverDetails, { plan: '' });
        }, []
    );

    // loading and error SHALL NOT be true in the same time
    const [loading, error, errMessage, results] = useServiceHook(SubscriptionService.getSubscriptions, [custId, country]);

    const parseSubsPlan = (subs: ISubscriptionSummaryItem[]) => {  // assign standard plan name to each subscription item
        return subs.map((subs: ISubscriptionSummaryItem) => {
            if (flexiOwnPlanNames.includes(subs.plan.toLowerCase())) { subs.plan = SubscriptionPlan.flexiOwn; }
            else if (flexiOwnUsedPlanNames.includes(subs.plan)) {subs.plan = SubscriptionPlan.flexiOwnUsed; }
            else if (ravCreditHirePlanNames.includes(subs.plan)) {subs.plan = SubscriptionPlan.ravCreditHire; }
            else if (ravShareCoverPlanNames.includes(subs.plan)) {subs.plan = SubscriptionPlan.ravShareCover; }
            else if (ravShortTermPlanNames.includes(subs.plan)) {subs.plan = SubscriptionPlan.ravShortTerm; }
            else { subs.plan = SubscriptionPlan.flexi; }
            return subs;
        });
    };

    // formState context holds the customer attribute which 
    // indicates if he's entitled to new subscriptions.
    const displayCreateBtn = !formState.driverDetails.blacklisted;

    const selectSubscriptionLabelStyle: CSSProperties = {
        margin: '0',  // set to 0 to mitigate p tag's default margin value
        padding: '2vh 0 2vh 2.5vw',
        color: '#2C2A2D',
        fontWeight: '700',
        borderBottom: '1px solid #C4C4C4'
    };

    const subscriptions = loading === false ? parseSubsPlan(results) : [];

    return (<>
        <NavBar pageTitle="Onboarding" />
        <p style={selectSubscriptionLabelStyle}>
            Subscription history
        </p>
        {loading === true
            ? <LoadingSpinner />
            : subscriptions.length > 0
                ? <SubscriptionsListPanel
                    keyName='onboarding-subscriptions'
                    items={subscriptions.map((subs: ISubscriptionSummaryItem) => {
                        return {
                            subsId: subs.subsId,
                            startDate: subs.startDate,
                            carDetails: subs.carDetails,
                            registration: subs.registration,
                            plan: subs.plan,
                            status: subs.status,
                            onClickFn: () => navigate(`${subs.subsId}`)
                        }
                    })}
                />
                : <NoSubscriptionsInfoNote />
        }
        {loading === false &&
            <div style={{ margin: '2.5vh 3.5vw' }}>
                {displayCreateBtn
                    ? <Button
                        label='Create subscription'
                        onClickFunc={() => navigate(`/payment-method/${country}/${custId}/review?next_activity=onboard&next_page=/onboarding/${country}/${custId}/vehicle-assignment`)}
                    />
                    : <WarningBox message={"There are restrictions on this account and therefore new subscriptions cannot be created at this time. Please contact Head of Ops for more information."} />
                }
            </div>
        }
    </>);
};


const LoadingSpinner = () => (
    <div style={{ display: 'flex', justifyContent: 'center', margin: '15vh' }}>
        <CircularSpinner />
    </div>
);


const NoSubscriptionsInfoNote = () => (
    <div style={{ margin: '1.5vh 3.5vw' }}>
        <InfoBox message='Please note that the customer does not have any subscriptions on their record' />
    </div>
);
