import React, { ChangeEvent, useState } from "react";

import {
  UserFormStates,
  useUserJourneyContext,
} from "@context/UserJourneyContext";
import Label from "@components/inputs/text/Label";
import DropDownMenu, { IChoice } from "@components/inputs/DropDownMenu";
import RadioInput from "@components/inputs/RadioInput";
import { WarningBox } from "@components/cards/messageBox";
import { addWeeks, dateToISOFormat } from "@utils/misc/functions";

/**
 * View intended to be integrated into the Flexi Own onboarding form.
 *
 * The following values in the User context are used:
 * - contractDetails.ipbDiscountAmount - used to store the IPB discount amount and
 *     is used to calculate the value of `Weekly subscription with discounts applied`
 *
 */

interface InsurancePriceBeatProps {
  couponChoices: IChoice[];
}

export default function InsurancePriceBeat({
  couponChoices,
}: InsurancePriceBeatProps) {
  const todaysDate = new Date();
  const discountStartDate = dateToISOFormat(todaysDate); // YYYY-mm-dd
  const discountEndDate = addWeeks(todaysDate, 52);

  const { formState, updateFormState } = useUserJourneyContext();
  const { contractDetails } = formState;
  const [hasInsurancePriceBeat, setHasInsurancePriceBeat] = useState<boolean>(false);
  const [ipbDiscountedWeeklyFee, setIpbDiscountedWeeklyFee] = useState<number>(0);

  const rowStyle: React.CSSProperties = {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  };

  const itemStyle: React.CSSProperties = {
    flex: 1,
    padding: "10px",
    boxSizing: "border-box",
  };

  const textStyle: React.CSSProperties = {
    ...itemStyle,
    fontWeight: "bold",
  };

  const numberStyle: React.CSSProperties = {
    ...itemStyle,
    textAlign: "right",
  };

  // Used to handle changes in the radio button when the customer has an Insurance Price Beat
  // so that the details of the insurance price beat appears
  const onIPBRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const ipbRadioValue = event.target.value;
    setHasInsurancePriceBeat(ipbRadioValue === "true");
    if (ipbRadioValue === "false") {
      // Resetting the IPB values so that when the Onboarding specialist changes
      // their option from Yes to No, then the IPB amount is also reset
      updateFormState(UserFormStates.contractDetails, { ipbDiscountAmount: 0 });
    }
  };

  // Used to handle changes in the dropdown so that the selected amount can be deducted from the weekly plan price
  const onSelectIPBAmount = (event: ChangeEvent<HTMLSelectElement>) => {
    const discountAmount = Number(event.currentTarget.value);
    updateFormState(UserFormStates.contractDetails, {
      ipbDiscountAmount: discountAmount,
    });
    setIpbDiscountedWeeklyFee(contractDetails.planWeeklyFee - discountAmount);
  };

  return (
    <>
      <WarningBox
        message={
          "Please ensure a price beat has been approved by the risk team before applying discount below. Ensure that the price beat approved matches the details of this subscription."
        }
      />
      <p style={{ margin: "2.5vh 0 0 0" }}>
        Does an insurance price beat discount apply to this subscription?
      </p>
      <RadioInput
        radioName="hasPriceBeat"
        options={[
          { label: "No", id: "pricebeat_no", value: "false" },
          { label: "Yes", id: "pricebeat_yes", value: "true" },
        ]}
        selectedValue={String(hasInsurancePriceBeat)}
        onSelect={onIPBRadioChange}
      />
      {hasInsurancePriceBeat && (
        <div data-testid="ipbSummary">
          <div style={{ margin: "2.5vh 0" }}>
            <Label text="Approved discount amount ($)" />
            <DropDownMenu
              menuName="planLenDropMenu"
              required={true}
              choices={couponChoices}
              onSelect={(e: ChangeEvent<HTMLSelectElement>) =>
                onSelectIPBAmount(e)
              }
              testId="planLenDropMenu"
            />
          </div>
          <div style={{ margin: "2.5vh 0" }}>
            <div style={rowStyle}>
              <div style={textStyle}>Discount weeks applied($)</div>
              <div style={numberStyle}>52</div>
            </div>

            <div style={rowStyle}>
              <div style={textStyle}>Start date of discount</div>
              <div style={numberStyle}>{discountStartDate}</div>
            </div>

            <div style={rowStyle}>
              <div style={textStyle}>End date of discount</div>
              <div style={numberStyle}>{dateToISOFormat(discountEndDate)}</div>
            </div>

            <div style={rowStyle}>
              <div style={textStyle}>
                Weekly subscription with discounts applied($)
              </div>
              <div style={numberStyle}>
                {contractDetails.ipbDiscountAmount !== 0 &&
                  ipbDiscountedWeeklyFee}
              </div>
            </div>

            <div style={rowStyle}>
              <div style={textStyle}>
                Weekly subscription after discount period($)
              </div>
              <div style={numberStyle}>{contractDetails.planWeeklyFee}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
